import React, { Component } from "react";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import withRouter from "../../../components/Common/withRouter";
import { withTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import avatar2 from "../../../assets/images/users/avatar-2.jpg";
import BACKEND_DOMAIN from "../../../service";

function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

class ProfileMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menu: false,
            profileImage: null,
            username: null,
        };
        this.toggle = this.toggle.bind(this);
    }

    async componentDidMount() {
        try {
            // Fetch user details from the API
            const response = await fetch(`${BACKEND_DOMAIN}/business/merchant/detail`, {
                headers: {
                    Authorization: `Bearer ${
                        localStorage.getItem("authUser") ? JSON.parse(localStorage.getItem("authUser")).token : ""
                    }`,
                },
            });

            if (!response.ok) {
                throw new Error("Error fetching user details");
            }

            const userData = await response.json();
            const unformattedName = userData.name;
            const username = capitalizeFirstLetter(unformattedName);

            this.setState({
                username,
                profileImage: userData.profile_image,
            });
        } catch (error) {
            console.error("Error fetching user details:", error.message);
        }
    }

    toggle() {
        this.setState((prevState) => ({
            menu: !prevState.menu,
        }));
    }

    handleLogout = () => {
        // Remove the current user
        localStorage.removeItem("authUser");
        localStorage.removeItem("authToken");
        // Check if "merchantUser" exists and remove it if it does
        const merchantInfo = localStorage.getItem("merchantUser");
        if (merchantInfo) {
            localStorage.removeItem("merchantUser");
        }
    
        // Navigate to the login page
        this.props.router.navigate("/route-money/merchant/login");
    };
    


    handleProfile = () => {
        this.props.router.navigate("/route-money/merchant/profile");
    };

     handleSwitch = async () => {
        const currentMerchInfo=  localStorage.getItem("authUser");
        const merchantInfo = localStorage.getItem("merchantUser");

                // Parse the JSON string into an object
        const merchantInfoObject = JSON.parse(merchantInfo);
        const currentMerchantInfoObject = JSON.parse(currentMerchInfo);

        const merchantEmail = merchantInfoObject.email;
        const currentToken = currentMerchantInfoObject.token;
        // Now you can access properties like `email`
        // console.log("merchant email ", merchantInfoObject.email);

        // console.log('current branch token ',currentMerchantInfoObject.token);

        const AccountApi = `https://backoffice.route.money/api/v1/admin/merchants/switch-user/`;
 
    
        try {
            const response = await fetch(AccountApi, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${currentToken}`,
                },
                body: JSON.stringify({
                    email: merchantEmail,
                }),
            });
    
            const data = await response.json();
    
            if (!response.ok) {
                console.log('Failed to switch account:', data);
    
                throw new Error(data.message || "Failed to switch account");
            } else {
        
                // Store the new user data
                const currentMerchInfo=  localStorage.getItem("authUser");
    
           
                //localStorage.setItem("merchantUser",currentMerchInfo);
                localStorage.setItem("authUser", JSON.stringify(data.user));
                localStorage.removeItem("merchantUser");
               // console.log("cleared merchant info ",merchantInfo);
                const token = data.user.token;
                 
                localStorage.setItem("authToken",token);
                // console.log('Merchant data:', data.user);
                // console.log('Auth Token:',token);
                // Open a new tab and navigate to the dashboard
                const systemAbsolutePath = window.location.href;

                console.log('system absolute path ',systemAbsolutePath)

                const baseAbsolutePath = systemAbsolutePath.split('#/route-money/')[0] + '#/route-money/';

                console.log('Base Absolute Path: ', baseAbsolutePath);
        
    
                // Define the new relative path you want to navigate to
                const newRelativePath = 'dashboard'; 

                // Construct the new absolute path
                const newAbsolutePath = `${baseAbsolutePath}${newRelativePath}`;
                console.log('New Absolute Path: ', newAbsolutePath);
                
                const newTab = window.open(newAbsolutePath, '_blank');
          
                // const newTab = window.open('http://localhost:3000/#/route-money/dashboard', '_blank');
          
    
                if (newTab) {
                    newTab.focus();
                } else {
                    console.error('Failed to open a new tab. Please check your browser settings.');
                }
            }
        } catch (e) {
            console.error("Failed to switch account-> ", e);
            throw e; // Throw error to indicate failure
        }
    };
    
    render() {
        const { username, profileImage } = this.state;
        const merchantUserExists = !!localStorage.getItem("merchantUser");


        return (
            <React.Fragment>
                <Dropdown isOpen={this.state.menu} toggle={this.toggle} className="d-inline-block user-dropdown">
                    <DropdownToggle
                        tag="button"
                        className="btn header-item waves-effect"
                        id="page-header-user-dropdown"
                    >
                        <img
                            className="rounded-circle header-profile-user me-1"
                            style={{ objectFit: "cover" }}
                            src={profileImage}
                            alt=""
                        />
                        <span className="d-none d-xl-inline-block ms-1">
                            <strong>{username}</strong>
                        </span>
                        <i className="mdi mdi-chevron-down d-none ms-1 d-xl-inline-block"></i>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-end">
                        <DropdownItem onClick={this.handleProfile}>
                            <i className="ri-user-line align-middle me-1"></i> Profile
                        </DropdownItem>
                      
                        {merchantUserExists && (
                            <>
                              <DropdownItem divider />
                                <DropdownItem className="text-success" onClick={this.handleSwitch}>
                                    <i className="ri-user-line align-middle me-1"></i> Switch Account
                                </DropdownItem>
                              
                            </>
                        )}
                        <DropdownItem divider />
                        <DropdownItem className="text-danger" onClick={this.handleLogout}>
                            <i className="ri-shut-down-line align-middle me-1 text-danger"></i> Logout
                        </DropdownItem>
                    </DropdownMenu>
                </Dropdown>
            </React.Fragment>
        );
    }
}

export default withRouter(ProfileMenu);
