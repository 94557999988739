import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Container, Row, Col,Spinner } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import MiniWidgets from "./MiniWidgets";
import RevenueAnalytics from "./RevenueAnalytics";
import SalesAnalytics from "./SalesAnalytics";
import EarningReports from "./EarningReports";
import LatestTransactions from "./LatestTransactions";
import RecentOrders from "./RecentOrders";
import {formatNumber} from './FigureFormatter';
import {
    fetchProductsOrdersAndBranches,
    fetchProductOffersData,
    fetchCashbackOffersData,
    fetchWalletBalanceData,
    fetchChurnRate,
    fetchTotalARPU,
    fetchTotalMerchants,
    fetchTotalUsers,
    fetchMonthlyRevenue,
    fetchUpdatedgvm
} from "./data_fetching/dashboardData";

const Dashboard = ({ isBusinessPartner }) => {
    const authToken = useSelector((state) => state.Login.token);

    const [localIsBusinessPartner, setLocalIsBusinessPartner] = useState(isBusinessPartner);

    const [productsCount, setProductsCount] = useState(0);
    const [branchesCount, setBranchesCount] = useState(0);
    const [ordersCount, setOrdersCount] = useState(0);
    const [productOffers, setProductOffers] = useState(null);
    const [cashbackOffers, setCashbackOffers] = useState(0);
    const [walletBalance, setWalletBalance] = useState(null);

    // admin car items
    const [churnRate, setChurnRate] = useState(0);
    const [arpu, setArpu]= useState(0);
    const [allMerchants, setAllMerchants] = useState(0);
    // const [totalMerchants, setTotalMerchants] = useState(0);
    const [totalUsers, setTotalUsers] = useState(0);
    const [monthlyRevenue, setMonthlyRevenue] = useState(null);
    const [allcarddata, setAllCardData] = useState([]);
    const [gvm, setGVM]= useState(0);
    const [adminWallet, setAdminWallet]= useState(null)
  

    const breadcrumbItems = [
        { title: "Route Money", link: "/" },
        { title: "Dashboard", link: "#" },
    ];

    useEffect(() => {
        const fetchIsPartner = async () => {
            try {
                const localData = localStorage.getItem("authUser");
                if (localData) {
                    const info = JSON.parse(localData);
                    setLocalIsBusinessPartner(info.is_business_partner);
                }
            } catch (e) {
                console.error(e);
            }
        };

        fetchIsPartner();
    }, []);

    useEffect(() => {
        const setDashboardInfo = async (token) => {
            try {
                const [
                    dashboardData,
                    productOffersCount,
                    cashbackOffersCount,
                    walletBalanceData,
                ] = await Promise.all([
                    fetchProductsOrdersAndBranches(token),
                    fetchProductOffersData(token),
                    fetchCashbackOffersData(token),
                    fetchWalletBalanceData(token),
                    fetchTotalARPU(token),
                    fetchTotalMerchants(token),
                    fetchTotalUsers(token),
                    fetchMonthlyRevenue(token),
                    fetchChurnRate(token),
                ]);

                setProductsCount(dashboardData.cards_data.products_count);
                setBranchesCount(dashboardData.cards_data.branches_count);
                setOrdersCount(dashboardData.cards_data.orders_count);
                setProductOffers(productOffersCount);
                setCashbackOffers(cashbackOffersCount);
                setWalletBalance(walletBalanceData.ledger_balance);
       
            } catch (error) {
                console.error("Error fetching dashboard data:", error);
            }
        };

        const setSuperDashboardInfo = async (token) => {
            try {
                const [
            
                    gvms
                ] = await Promise.all([
                 
                    fetchUpdatedgvm(token)
                ]);
    
              // console.log("Fetched GVM:", gvms);

                    // Set the GVM data directly from `gvms` without using `allcarddata`
                    setChurnRate(gvms.churn_rate);
                    setArpu(gvms.arpu);
                    setAllMerchants(gvms.total_merchants);
                    setTotalUsers(gvms.total_users);
                    setMonthlyRevenue(gvms.monthly_revenue);
                    setGVM(gvms.gmv);
                    setAdminWallet(gvms.wallet_balance.available_balance);
                
    
                // Set other data
              
            } catch (error) {
                console.error("Error fetching dashboard data:", error);
            }
        };
    
        if (authToken) {
            setDashboardInfo(authToken);
            setSuperDashboardInfo(authToken);
        }
    }, [authToken]);
    
    
    

 
    const businessPartnerReports = [
        {
            icon: "ri-currency-line",
            title: "Wallet Balance",
            value: walletBalance > 0 
                ? `KES ${formatNumber(walletBalance)}`
                : walletBalance === 0 
                    ? "KES 0.00" 
                    : <div><Spinner size="sm" color="white" /></div>,
            rate: "2.4%",
            desc: "From previous month",
            routeTo: "#",
        },
        {
            icon: "ri-shopping-bag-2-line",
            title: "Number of Products",
            value: productsCount ?
            `${productsCount}`: <div><Spinner size="sm" color="white" /></div>
            ,
            rate: "2.4%",
            desc: "From previous month",
            routeTo: "/route-money/merchant/products",
        },
        {
            icon: "ri-shopping-cart-2-line",
            title: "Total Orders",
            value: ordersCount ? `${ordersCount}`:<div><Spinner size="sm" color="white" /></div>,
            rate: "2.4%",
            desc: "From previous month",
            routeTo: "/route-money/merchant/order-history",
        },
        {
            icon: "ri-store-line",
            title: "Your Branches",
            value: branchesCount ?`${branchesCount}`:<div><Spinner size="sm" color="white" /></div>,
            rate: "2.4%",
            desc: "From previous month",
            routeTo: "/route-money/merchant/branch/list",
        },
        {
            icon: "ri-coupon-line",
            title: "Product Offers",
            value: productOffers ? `${productOffers}`: 0,
            rate: "2.4%",
            desc: "From previous month",
            routeTo: "/route-money/merchant/product-offers",
        },
        {
            icon: "ri-refund-2-line",
            title: "Cashback Offers",
            value: cashbackOffers ?`${cashbackOffers}`: 0,
            rate: "2.4%",
            desc: "From previous month",
            routeTo: "/route-money/merchant/cashback-offers",
        },
    ];

  
    const superUserReports = [
        {
            icon: "ri-currency-line",
            title: "Master Wallet Bal",
            value: 
        (
            <span style={{ fontSize: '17px' }}> 
              {adminWallet > 0 
            ? `KES ${formatNumber(adminWallet)}`
            : adminWallet === 0 
                ? "KES 0.00" 
                : <div><Spinner size="sm" color="white" /></div>}
            </span>
        )
          ,
            rate: "2.4%",
            desc: "From previous month",
            routeTo: "/route-money/revenue-report",
        },
        {
            icon: "ri-stack-line",
            title: "Gross Merchandise Value (GMV)",
            value: (
                <span style={{ fontSize: '17px' }}> 
                    {gvm ? `KES ${formatNumber(gvm)}` : <div><Spinner size="sm" color="white" /></div>}
                </span>
            ),
            rate: "2.4%",
            desc: "From previous month",
            routeTo: "/route-money/collection-transactions",
        }
        
         ,
        {
            icon: "ri-wallet-2-line",
            title: "Monthly Revenue",
    
            value:
            (
                <span style={{ fontSize: '17px' }}> 
            {monthlyRevenue > 0 
            ? `KES ${formatNumber(monthlyRevenue)}`
            : monthlyRevenue === 0 
                ? "KES 0" 
                : <div><Spinner size="sm" color="white" /></div>}
                </span>),
            rate: "2.4%",
            desc: "From previous month",
            routeTo: "/route-money/revenue-report",
        },
        {
            icon: "ri-group-line",
            title: "Number of Users",
            value: totalUsers ? `${totalUsers}`: <div><Spinner size="sm" color="white" /></div>,
            rate: "2.4%",
            desc: "From previous month",
            routeTo: "/route-money/customers",
        },
        {
            icon: "ri-medium-line",
            title: "Average Revenue Per User (ARPU)",
            value:
            (
                <span style={{ fontSize: '17px' }}> 
            {arpu ? `KES ${formatNumber(arpu)}`: <div><Spinner size="sm" color="white" /></div>}
            </span>),
            rate: "2.4%",
            desc: "From previous month",
            routeTo: "/route-money/merchants",
        },
        {
            icon: "ri-group-2-line",
            title: "Customer Churn Rate",
            value:
            (
                <span style={{ fontSize: '17px' }}> 
            {churnRate > 0 
            ? `${churnRate}`
            : churnRate === 0 
                ? " 0" 
                : <div><Spinner size="sm" color="white" /></div>}
                </span>),
            rate: "2.4%",
            desc: "From previous month",
            routeTo: "/route-money/customers",
        },
    ];

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    {!localIsBusinessPartner && (
                        <>
                            <Breadcrumbs title="Admin Dashboard" breadcrumbItems={breadcrumbItems} />

                            <Row>
                                <Col xl={8}>
                                    <Row>
                                        <MiniWidgets reports={superUserReports} />
                                    </Row>
                                    <RevenueAnalytics />
                                </Col>
                                <Col xl={4}>
                                    <SalesAnalytics isAdmin={true} />
                                    <EarningReports />
                                </Col>
                            </Row>
                            <Row>
                                <LatestTransactions />
                                <RecentOrders isAdmin={true} />
                            </Row>
                        </>
                    )}
                    {localIsBusinessPartner && (
                        <>
                            <Breadcrumbs title="Merchant Dashboard" breadcrumbItems={breadcrumbItems} />

                            <Row>
                                <Col xl={8}>
                                    <Row>
                                        <MiniWidgets reports={businessPartnerReports} />
                                    </Row>
                                    <RevenueAnalytics />
                                </Col>
                                <Col xl={4}>
                                    <SalesAnalytics isAdmin={false} />
                                    <EarningReports />
                                </Col>
                            </Row>
                            <Row>
                                <LatestTransactions />
                                <RecentOrders isAdmin={false} />
                            </Row>
                        </>
                    )}
                </Container>
            </div>
        </>
    );
};

export default Dashboard;